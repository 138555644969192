import Box from "@mui/material/Box";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import { isDuplicateProjectName } from "@/features/portfolio/utils/portfolioHelpers";
import { RSelectFormInput, RTextFormInput } from "@/shared/components/forms";
import {
  FIELD_LENGTH,
  FIELD_REQUIRED,
  LONG_NAMES,
  FIELD_IN_USE,
} from "@/shared/constants";
import ProjectRegion from "@/shared/enums/projectRegion";
import { validateFormFields } from "@/shared/utils/formValidation";

import editProject from "../../api/editProjectService";
import { EditProjectModel } from "../../models/editProjectModel";
import { PortfolioRowModel } from "../../models/portfolioModel";

export type EditProjectFormProps = {
  project: PortfolioRowModel;
  onClose: () => void;
  refetchProjects: () => Promise<void>;
  innerRef: RefObject<FormikProps<EditProjectModel>>;
  setIsFormValid?: (isValid: boolean) => void;
  portfolioData: PortfolioRowModel[];
};

export default function EditProjectForm({
  project,
  onClose,
  refetchProjects,
  innerRef,
  setIsFormValid,
  portfolioData,
}: EditProjectFormProps) {
  const validationSchema = yup.object({
    name: yup
      .string()
      .max(LONG_NAMES, FIELD_LENGTH)
      .required(FIELD_REQUIRED)
      .test(
        "unique-name",
        FIELD_IN_USE,
        (value) =>
          !value ||
          !isDuplicateProjectName(value, portfolioData) ||
          value === project.name
      ),
    programmeName: yup.string().max(LONG_NAMES, FIELD_LENGTH).required(FIELD_REQUIRED),
    selectedDevelopmentOptionId: yup.string().required(FIELD_REQUIRED),
    lifeCycleStage: yup.number().required(FIELD_REQUIRED),
    riskLevel: yup.number().required(FIELD_REQUIRED),
  });
  const initialValues: EditProjectModel = {
    id: project.id,
    name: project.name,
    programmeName: project.programmeName,
    region: project.region,
    lifeCycleStage: project.lifeCycleStage,
    riskLevel: project.riskLevel,
    selectedDevelopmentOptionId: project.selectedDevelopmentOptionId,
    type: project.type,
  };

  const concepts = project.children.map((concept) => {
    return { id: concept.id, name: concept.name };
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={innerRef}
      onSubmit={async (values) => {
        try {
          await editProject(values);

          onClose();
          await refetchProjects();
          // You can use 'response' here as needed
        } catch (err) {
          if (err instanceof Error) {
            // Throw the error to be handled by a higher-level error boundary or handler
            throw new Error(`Failed to edit project: ${err.message}`);
          } else {
            // Throw a generic error if the error is not an instance of Error
            throw new Error("Failed to edit project: An unknown error occurred");
          }
        }
      }}>
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        setFieldTouched,
        validateField,
        isValid,
      }) => {
        validateFormFields<EditProjectModel>(
          isValid,
          touched,
          ["name", "programmeName", "region", "selectedDevelopmentOptionId"],
          setIsFormValid,
          true
        );

        const regionOptions = Object.values(ProjectRegion)
          .filter((value) => typeof value === "string")
          .map((value, index) => ({
            key: value,
            value: index,
            label: value,
          }));

        const conceptOptions = concepts.map((child) => ({
          key: child.id,
          value: child.id,
          label: child.name,
        }));

        return (
          <Form>
            <Box
              sx={{
                "& .MuiTextField-root": { mb: 2, width: "100%" },
                paddingTop: "1rem",
              }}>
              <RTextFormInput
                required
                name="name"
                label="Project Name"
                value={values.name}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
              />
              <RTextFormInput
                required
                name="programmeName"
                label="Programme Name"
                value={values.programmeName}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
              />
              <RSelectFormInput
                required
                name="region"
                label="Region"
                value={values.region}
                options={regionOptions}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <RSelectFormInput
                required
                name="selectedDevelopmentOptionId"
                label="Selected Concept"
                value={values.selectedDevelopmentOptionId ?? ""}
                options={conceptOptions}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}
