import { useState } from "react";

import InviteUserForm from "@/features/tenantManagement/components/userManagement/forms/InviteUserForm";
import { PrimaryButton } from "@/shared/components/buttons";
import { RDialog } from "@/shared/components/forms";

export default function InviteUsersButton() {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <PrimaryButton onClick={handleOpen}>INVITE USERS</PrimaryButton>
      <RDialog
        isOpen={isModalOpen}
        title="INVITE NEW USER"
        cancelButtonCallback={handleClose}
        // confirmButtonCallback={handleDialogConfirm}
        confirmButtonText="INVITE"
        confirmButtonType="primary"
        size="medium">
        <InviteUserForm />
      </RDialog>
    </>
  );
}
