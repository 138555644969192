import { Typography } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Box } from "@mui/system";
import { FormikErrors } from "formik";
import { useState, ChangeEvent, FocusEvent } from "react";

type RTextFormInputProps = {
  name: string; // unique identifier
  label: string; // title of text box
  value: string | number | null;
  maxLength: number; // what is the maximum length of characters in this field
  required?: boolean;
  errors: Record<string, string>; // contains the validation error message for a field
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleBlur: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  touched: Record<string, boolean>; // tracks which fields in the form have been touched
  setFieldTouched: (
    field: string,
    isTouched: boolean,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<unknown>>; // formik prop which marks a field as touched/untouched
  validateField: (field: string) => Promise<void> | Promise<string | undefined>; // manually validates a field
} & TextFieldProps; // Extend with TextFieldProps

const getUserFormStyles = (error: boolean) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "#EEF1F4",
  },
  boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.1)",
  "& .MuiFilledInput-underline::before": {
    borderBottom: "none",
  },
  "& .MuiInputLabel-root": {
    color: error ? "error.main" : "primary.main", // Red label if there's an error, otherwise blue
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: error ? "error.main" : "primary.main", // Red label if there's an error when focused, otherwise blue
  },
  marginBottom: "0 !important",
});

export default function RTextFormInput({
  name,
  label,
  value,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldTouched,
  validateField,
  maxLength,
  required,
  ...rest // Capture any additional props
}: RTextFormInputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const currentLength = value?.toString().length ?? 0;
  const hasExceededMaxLength = currentLength > maxLength;

  return (
    <>
      <TextField
        required={required}
        name={name}
        label={label + (touched[name] && errors[name] ? ` ${errors[name]}` : "")}
        type="string"
        variant="filled"
        value={value ?? ""}
        onBlur={(event) => {
          handleBlur(event);
          setIsFocused(false);
          void validateField(name);
        }}
        onChange={(event) => {
          handleChange(event);
          void setFieldTouched(name, true, false);
        }}
        onFocus={() => setIsFocused(true)}
        error={touched[name] && Boolean(errors[name])}
        sx={getUserFormStyles(touched[name] && errors[name] ? true : false)}
        fullWidth
        {...rest} // Spread additional props here
      />

      <Box
        sx={{
          height: "2rem",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}>
        {(isFocused || (currentLength > 0 && touched[name] && Boolean(errors[name]))) && (
          <Typography
            variant="body2"
            sx={{
              textAlign: "right",
              display: "block",
              color: hasExceededMaxLength
                ? "error.main" // Uses Material-UI's error color
                : "primary.main",
            }}>
            {currentLength}/{maxLength}
          </Typography>
        )}
      </Box>
    </>
  );
}
