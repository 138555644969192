import { Add } from "@mui/icons-material";
import { Box, Button, Tabs } from "@mui/material";
import { FormikProps } from "formik";
import { useRef, useState } from "react";

import AddWBSEntryForm from "@/features/projectScope/components/forms/AddWBSEntryForm";
import CloneWBSEntryForm, {
  FormValues,
} from "@/features/projectScope/components/forms/CloneWBSEntryForm";
import { AddWBSEntryModel } from "@/features/projectScope/types/projectScopeModels";
import CustomTab from "@/shared/components/CustomTab";
import CustomTabPanel from "@/shared/components/CustomTabPanel";
import { RDialog } from "@/shared/components/forms";
import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";

export default function AddWBSEntry({
  wbsEntry,
  clearRowSelection,
}: {
  wbsEntry: WBSEntryViewModel;
  clearRowSelection: () => void;
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const addFormikRef = useRef<FormikProps<AddWBSEntryModel>>(null);
  const cloneFormikRef = useRef<FormikProps<FormValues>>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmitForm = () => {
    if (isModalOpen && addFormikRef.current) {
      void addFormikRef.current.submitForm();
      setModalOpen(false);
    } else if (isModalOpen && cloneFormikRef.current) {
      void cloneFormikRef.current.submitForm();
      setModalOpen(false);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const [isActiveTab, setActiveTab] = useState("new");

  return (
    <>
      <Button
        variant="contained"
        sx={{
          color: "white",
          width: "fit-content",
          maxWidth: "fit-content",
          marginTop: "24px",
          transition: "transform 0.2s",
          "&:focus": {
            outline: "none",
          },
          "&:hover": {
            color: "white",
          },
          "&:visited": {
            color: "white",
          },
          marginLeft: "0.5rem",
        }}
        startIcon={<Add fontSize="small" />}
        onClick={() => setModalOpen(true)}>
        Add Outcome
      </Button>
      <RDialog
        isOpen={isModalOpen}
        title="Add New Outcome"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleSubmitForm}
        confirmButtonText={isActiveTab === "new" ? "Create" : "Import"}
        confirmButtonType="primary"
        confirmButtonDisabled={!isFormValid}
        size="medium">
        <Box minWidth="400px">
          <Tabs
            value={isActiveTab}
            style={{ marginBottom: "1.5rem" }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#000",
                color: "#003057",
              },
            }}
            sx={{
              "& .MuiTab-root": {
                fontSize: "1.1rem",
                fontWeight: "bold",
                flex: 1,
              },
            }}>
            <CustomTab<string> label="New" value="new" setActiveTab={setActiveTab} />
            {wbsEntry && wbsEntry?.level < 3 && (
              <CustomTab<string>
                label="From Existing"
                value="clone"
                setActiveTab={setActiveTab}
              />
            )}
          </Tabs>
          <CustomTabPanel name="new" value={isActiveTab}>
            <AddWBSEntryForm
              wbsEntry={wbsEntry}
              innerRef={addFormikRef}
              setIsFormValid={setIsFormValid}
              handleClose={handleClose}
            />
          </CustomTabPanel>
          {wbsEntry && wbsEntry?.level < 3 && (
            <CustomTabPanel name="clone" value={isActiveTab}>
              <CloneWBSEntryForm
                clearRowSelection={clearRowSelection}
                data={wbsEntry}
                handleClose={handleClose}
                innerRef={cloneFormikRef}
              />
            </CustomTabPanel>
          )}
        </Box>
      </RDialog>
    </>
  );
}
