import Box from "@mui/material/Box";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import { isDuplicateConceptName } from "@/features/portfolio/utils/portfolioHelpers";
import {
  RNumberFormInput,
  RSelectFormInput,
  RTextFormInput,
} from "@/shared/components/forms";
import RChip from "@/shared/components/RChip";
import {
  FIELD_IN_USE,
  FIELD_LENGTH,
  FIELD_REQUIRED,
  LONG_NAMES,
  PROJECT_CODE_RANGE,
} from "@/shared/constants";
import LifeCycleStage from "@/shared/enums/lifeCycleStage";
import Risk, { getRiskColorFromIndex } from "@/shared/enums/risk";
import ProjectScenarios from "@/shared/enums/scenarios";
import { getTextColorTemp } from "@/shared/utils";
import { validateFormFields } from "@/shared/utils/formValidation";

import { AddConceptModel } from "../../models/addConceptModel";
import { PortfolioRowModel } from "../../models/portfolioModel";

export type AddConceptFormProps = {
  projectId?: string;
  onConfirm: (newConcept: AddConceptModel) => void;
  innerRef: RefObject<FormikProps<AddConceptModel>>;
  portfolioData?: PortfolioRowModel[];
  setIsFormValid?: (isValid: boolean) => void;
};

export default function AddConceptForm({
  projectId,
  onConfirm,
  innerRef,
  portfolioData = [],
  setIsFormValid,
}: AddConceptFormProps) {
  const validationSchema = yup.object({
    name: yup
      .string()
      .max(LONG_NAMES, FIELD_LENGTH)
      .required(FIELD_REQUIRED)
      .test(
        "unique-name",
        FIELD_IN_USE,
        (value) => !value || !isDuplicateConceptName(value, portfolioData)
      ),
  });

  const scenarioOptions = Object.values(ProjectScenarios)
    .filter((value) => typeof value === "string")
    .map((value, index) => ({
      key: value,
      value: index,
      label: value,
      disabled: index !== 0,
      chip: index !== 0 ? <RChip label="PRO" /> : undefined,
    }));
  const lifeCycleOptions = Object.values(LifeCycleStage)
    .filter((value) => typeof value === "string")
    .map((value, index) => ({
      key: value,
      value: index,
      label: value,
    }));

  const riskOptions = Object.values(Risk)
    .filter((value) => typeof value === "string")
    .map((value, index) => ({
      key: value,
      value: index,
      label: value,
      chip: (
        <RChip
          backgroundColor={getRiskColorFromIndex(index)}
          color={getTextColorTemp(getRiskColorFromIndex(index))}
          style={{
            borderRadius: "0.5rem",
            height: "0.75rem",
            width: "2rem",
          }}
        />
      ),
    }));

  return (
    <Formik
      initialValues={{
        name: "",
        programmeName: "N/A",
        riskLevel: Risk.Low,
        lifeCycleStage: LifeCycleStage.Visualise,
        selectedScenario: ProjectScenarios.Transactional,
        parentId: projectId ?? "",
        projectCode: 1,
      }}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={(values) => {
        try {
          //@todo: below line is because an extensive change in backend is needed to use enums for scenario types instead of strings.
          values.selectedScenario = ProjectScenarios[values.selectedScenario as number];
          onConfirm(values);
        } catch (err) {
          if (err instanceof Error) {
            throw new Error(`Failed to confirm concept: ${err.message}`);
          } else {
            throw new Error("Failed to confirm concept: An unknown error occurred");
          }
        }
      }}
      innerRef={innerRef}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldTouched,
        validateField,
        isValid,
        setFieldValue,
      }) => {
        validateFormFields<AddConceptModel>(isValid, touched, ["name"], setIsFormValid);
        return (
          <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
            <Form>
              <RTextFormInput
                required
                name="name"
                label="Concept Name"
                value={values.name}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
              />
              <RSelectFormInput
                required
                name="selectedScenario"
                label="Commercial Scenario"
                value={values.selectedScenario}
                options={scenarioOptions}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <RSelectFormInput
                required
                name="lifeCycleStage"
                label="Life Cycle Stage"
                value={values.lifeCycleStage}
                options={lifeCycleOptions}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <RSelectFormInput
                required
                name="riskLevel"
                label="Risk Level"
                value={values.riskLevel}
                options={riskOptions}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <RNumberFormInput
                name="projectCode"
                label="Project Code"
                value={values.projectCode}
                onInputChange={(newValue) => {
                  void setFieldValue("projectCode", newValue);
                }}
                decimalScale={0}
                range={PROJECT_CODE_RANGE}
              />
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}
