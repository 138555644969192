// @refactor find a better way to deal with empty values in textfield of type number. Alternatively avoid the transform in the yup schema and instead pass a null in the onChange event of the text field
import Box from "@mui/material/Box";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as Yup from "yup";

import editResource from "@/features/cost/resourceCatalogue/api/editResource";
import { ResourcesModel } from "@/features/cost/resourceCatalogue/types/resourcesModel";
import {
  RNumberFormInput,
  RSelectFormInput,
  RTextFormInput,
} from "@/shared/components/forms";
import {
  FIELD_REQUIRED,
  SHORT_NAMES,
  FIELD_LENGTH,
  LONG_NAMES,
  SHORT_DESCRIPTION,
  MID_AMOUNT_RANGE,
} from "@/shared/constants";
import { OutcomeUnitsNames, DurationUnitsNames } from "@/shared/enums/units";
import { validateFormFields } from "@/shared/utils/formValidation";

const validationSchema = Yup.object({
  category: Yup.string().required(FIELD_REQUIRED).max(SHORT_NAMES, FIELD_LENGTH),
  subCategory: Yup.string().required(FIELD_REQUIRED).max(SHORT_NAMES, FIELD_LENGTH),
  supplier: Yup.string().required(FIELD_REQUIRED).max(SHORT_NAMES, FIELD_LENGTH),
  name: Yup.string().required(FIELD_REQUIRED).max(LONG_NAMES, FIELD_LENGTH),
  description: Yup.string().max(SHORT_DESCRIPTION, FIELD_LENGTH),
});

export type EditResourceCatalogueFormProps = {
  row: ResourcesModel;
  onClose: () => void;
  onCatalogueAndCostRefetch: () => void;
  innerRef: RefObject<FormikProps<ResourcesModel>>;
  setIsFormValid?: (isValid: boolean) => void;
};

export default function EditResourceCatalogueForm({
  row,
  onClose,
  onCatalogueAndCostRefetch,
  innerRef,
  setIsFormValid,
}: EditResourceCatalogueFormProps) {
  const initialValues: ResourcesModel = {
    id: row.id,
    category: row.category,
    subCategory: row.subCategory,
    name: row.name,
    supplier: row.supplier,
    description: row.description,
    fixedCost: row.fixedCost,
    dynamicCost: row.dynamicCost,
    unit: row.unit,
    unitEnum: row.unitEnum,
    section: row.section,
    unitCost: row.unitCost,
  };

  const units = new Map<number, string>([...DurationUnitsNames, ...OutcomeUnitsNames]);
  const unitOptions = Array.from(units.entries()).map(([key, value]) => ({
    key: value,
    value: key,
    label: value,
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      innerRef={innerRef}
      onSubmit={async (values) => {
        try {
          await editResource(values);

          onClose();
          onCatalogueAndCostRefetch();
          // You can use 'response' here as needed
        } catch (err) {
          if (err instanceof Error) {
            // Throw the error to be handled by a higher-level error boundary or handler
            throw new Error(`Failed to edit resource: ${err.message}`);
          } else {
            // Throw a generic error if the error is not an instance of Error
            throw new Error("Failed to edit resource: An unknown error occurred");
          }
        }
      }}>
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        validateField,
        isValid,
      }) => {
        validateFormFields<ResourcesModel>(
          isValid,
          touched,
          ["name", "description", "supplier", "subCategory", "category"],
          setIsFormValid,
          true
        );
        return (
          <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
            <Form>
              <RTextFormInput
                name="category"
                label="Category"
                value={values.category}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={SHORT_NAMES}
                required
              />
              <RTextFormInput
                name="subCategory"
                label="SubCategory"
                value={values.subCategory}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={SHORT_NAMES}
                required
              />
              <RTextFormInput
                name="supplier"
                label="Supplier"
                value={values.supplier}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={SHORT_NAMES}
                required
              />
              <RTextFormInput
                name="name"
                label="Resource Name"
                value={values.name}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
                required
              />
              <RTextFormInput
                name="description"
                label="Description"
                value={values.description}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={SHORT_DESCRIPTION}
              />
              <RSelectFormInput
                name="unit"
                label="Unit"
                value={values.unit}
                options={unitOptions}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              {/* <Box mb={3.4}>
                <TextField
                  select
                  label="Unit"
                  name="unit"
                  value={values.unitEnum}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const value = Number(event.target.value);
                    onUnitChanged(value, setFieldValue); // Use setFieldValue to update both unit and unitEnum
                  }}
                  error={touched.unit && Boolean(errors.unit)}
                  helperText={touched.unit && errors.unit}
                  fullWidth>
                  {Array.from(units).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box> */}
              <RNumberFormInput
                name="fixedCost"
                label="Fixed Price"
                value={values.fixedCost}
                allowNegative
                onInputChange={(newValue) => {
                  void setFieldValue("fixedCost", newValue);
                }}
                range={MID_AMOUNT_RANGE}
              />
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}
