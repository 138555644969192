import Box from "@mui/material/Box";
import { Formik, Form, FormikProps } from "formik";
import { MRT_Row } from "material-react-table";
import { RefObject } from "react";
import * as Yup from "yup";

import { isDuplicateConceptName } from "@/features/portfolio/utils/portfolioHelpers";
import {
  RNumberFormInput,
  RSelectFormInput,
  RTextFormInput,
} from "@/shared/components/forms";
import RChip from "@/shared/components/RChip";
import {
  FIELD_LENGTH,
  FIELD_REQUIRED,
  LONG_NAMES,
  PROJECT_CODE_RANGE,
  FIELD_IN_USE,
} from "@/shared/constants";
import LifeCycleStage from "@/shared/enums/lifeCycleStage";
import Risk, { getRiskColorFromIndex } from "@/shared/enums/risk";
import ProjectScenarios from "@/shared/enums/scenarios";
import { getTextColorTemp } from "@/shared/utils";
import { validateFormFields } from "@/shared/utils/formValidation";

import editConcept from "../../api/editConceptService";
import { EditConceptModel } from "../../models/editConceptModel";
import { PortfolioRowModel } from "../../models/portfolioModel";

export type EditConceptFormProps = {
  row: MRT_Row<PortfolioRowModel>;
  onClose: () => void;
  refetchProjects: () => Promise<void>;
  innerRef: RefObject<FormikProps<EditConceptModel>>;
  setIsFormValid?: (isValid: boolean) => void;
  portfolioData: PortfolioRowModel[];
};

export default function EditConceptForm({
  row,
  onClose,
  refetchProjects,
  innerRef,
  setIsFormValid,
  portfolioData,
}: EditConceptFormProps) {
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(LONG_NAMES, FIELD_LENGTH)
      .required(FIELD_REQUIRED)
      .test(
        "unique-name",
        FIELD_IN_USE,
        (value) =>
          !value ||
          !isDuplicateConceptName(value, portfolioData) ||
          value === row.original.name
      ),
  });
  const initialValues: EditConceptModel = {
    id: row.original.id,
    type: row.original.type,
    name: row.original.name,
    programmeName: row.original.programmeName,
    selectedScenario: row.original.selectedScenario,
    region: row.original.region,
    lifeCycleStage: row.original.lifeCycleStage,
    riskLevel: row.original.riskLevel,
    netPresentValue: row.original.netPresentValue,
    internalRateOfReturn: row.original.internalRateOfReturn,
    projectCode: row.original.projectCode || 1,
  };

  const scenarioOptions = Object.values(ProjectScenarios)
    .filter((value) => typeof value === "string")
    .map((value, index) => ({
      key: value,
      value: index,
      label: value,
      disabled: index !== 0,
      chip: index !== 0 ? <RChip label="PRO" /> : undefined,
    }));

  const lifeCycleOptions = Object.values(LifeCycleStage)
    .filter((value) => typeof value === "string")
    .map((value, index) => ({
      key: value,
      value: index,
      label: value,
    }));

  const riskOptions = Object.values(Risk)
    .filter((value) => typeof value === "string")
    .map((value, index) => ({
      key: value,
      value: index,
      label: value,
      chip: (
        <RChip
          backgroundColor={getRiskColorFromIndex(index)}
          color={getTextColorTemp(getRiskColorFromIndex(index))}
          style={{
            borderRadius: "0.5rem",
            height: "0.75rem",
            width: "2rem",
          }}
        />
      ),
    }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={async (values) => {
        try {
          await editConcept(values);
          onClose();
          await refetchProjects();
        } catch (err) {
          if (err instanceof Error) {
            throw new Error(`Failed to edit concept: ${err.message}`);
          } else {
            throw new Error("Failed to edit concept: An unknown error occurred");
          }
        }
      }}
      innerRef={innerRef}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldTouched,
        validateField,
        isValid,
        setFieldValue,
      }) => {
        validateFormFields<EditConceptModel>(
          isValid,
          touched,
          ["name"],
          setIsFormValid,
          true
        );

        return (
          <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
            <Form>
              <RTextFormInput
                required
                name="name"
                label="Concept Name"
                value={values.name}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
              />
              <RSelectFormInput
                required
                name="selectedScenario"
                label="Commercial Scenario"
                value={values.selectedScenario}
                options={scenarioOptions}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <RSelectFormInput
                required
                name="lifeCycleStage"
                label="Life Cycle Stage"
                value={values.lifeCycleStage}
                options={lifeCycleOptions}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <RSelectFormInput
                required
                name="riskLevel"
                label="Risk Level"
                value={values.riskLevel}
                options={riskOptions}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <RNumberFormInput
                name="projectCode"
                label="Project Code"
                value={values.projectCode}
                onInputChange={(newValue) => {
                  void setFieldValue("projectCode", newValue);
                }}
                decimalScale={0}
                range={PROJECT_CODE_RANGE}
              />
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}
