import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { FormikErrors } from "formik";
import { useEffect, useState } from "react";

import { WBSEntryModel } from "@/shared/types/projectScopeModel";

type OptionType = {
  id: string;
  label: string;
};
const getSelectFormStyles = () => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "#EEF1F4",
  },
  boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.1)",
  "& .MuiFilledInput-underline::before": {
    borderBottom: "none",
  },
  "& .MuiInputLabel-root": {
    color: "primary.main",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "primary.main",
  },
  marginBottom: "2rem !important",
  "&:last-child": {
    marginBottom: "0 !important",
  },
});

export default function ReusableAutocomplete(props: {
  id: string;
  name: string;
  label: string;
  value: OptionType;
  handleChange: (
    field: string,
    value: OptionType,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<{
    project: OptionType;
    option: OptionType;
    outcome: OptionType;
    level2: OptionType;
    level3: OptionType;
    level4: OptionType;
  }>>;
  handleBlur: {
    (e: React.FocusEvent<string, Element>): void;
    <T = string>(fieldOrEvent: T): T extends string ? (e: string) => void : void;
  };
  func: () => Promise<WBSEntryModel[] | undefined>;
}) {
  const { id, name, label, value, handleChange, handleBlur, func } = props;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly OptionType[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    const fetchData = async () => {
      try {
        const data = await func();
        if (active && data) {
          setOptions([
            ...data.map((r) => {
              return { id: r.id, label: r.name };
            }),
          ]);
        }
      } catch (error) {
        throw new Error(
          `Error fetching data: ${error instanceof Error ? error.message : String(error)}`
        );
      }
    };

    void fetchData();

    return () => {
      active = false;
    };
  }, [func, loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChangeWrapper = async (
    _e: React.ChangeEvent<unknown>, // Replaced {} with unknown
    newValue: OptionType | null
  ) => {
    if (newValue) {
      try {
        await handleChange(name, newValue);
      } catch (error) {
        throw new Error(
          `Error during handleChange: ${error instanceof Error ? error.message : String(error)}`
        );
      }
    }
  };

  return (
    <Autocomplete
      id={id}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      getOptionLabel={(option) => {
        return option.label || "";
      }}
      options={options}
      loading={loading}
      value={value.id ? value : null}
      onBlur={handleBlur}
      clearIcon={null}
      onChange={(_e, newValue) => {
        void handleChangeWrapper(_e, newValue); // Ensured promise is handled
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          sx={getSelectFormStyles}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
