import { AxiosError, AxiosResponse } from "axios";
import { Params } from "react-router-dom";

import { DELIVERABLES_API_ENDPOINTS } from "@/features/projectScope/api/endpoints";
import { SHARED_API_ENDPOINTS } from "@/shared/api/endpoints";
import apiRequests from "@/shared/config/axiosConfig";
import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";
import {
  LegacyEditWBSEntry,
  WBSEntryModel as ProjectScopeModel,
} from "@/shared/types/projectScopeModel";

/**
 * Retrieves a deliverable by its ID.
 * @param id The ID of the deliverable to retrieve.
 * @returns A Promise that resolves to a ProjectScopeModel object, or null if an error occurs.
 * @throws An error if the request fails or returns an error status code.
 */
export const getDeliverableById = async (id: string): Promise<WBSEntryModel | null> => {
  try {
    const url = DELIVERABLES_API_ENDPOINTS.GET_DELIVERABLE_BY_ID.replace("{id}", id);
    const response: AxiosResponse<WBSEntryModel> = await apiRequests.get(url);
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      return data;
    }

    switch (response.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(`Unknown error occurred with status code ${response.status}`);
    }
  } catch (e) {
    // @todo Handle Error Below
    // throw new Error("An unexpected error occurred");
    return null;
  }
};

/**
 * Retrieves the project deliverables for a given project ID..
 * @param projectid The ID of the project to retrieve deliverables for.
 * @returns A Promise that resolves to an array of ProjectScopeModel objects, or null if an error occurs.
 * @throws An error if the request fails or returns an error status code.
 */
// @todo: seems that this api call is not used but if is not trigger the first time a project is created and user access strategy definition it will never load. this method needs to be looked at in the backend because is doing more than getting data is probably also setting something
export const getProjectDeliverables = async (
  projectid: string
): Promise<WBSEntryModel[] | null> => {
  try {
    const url = DELIVERABLES_API_ENDPOINTS.GET_PROJECT_DELIVERABLES.replace(
      "{projectid}",
      projectid
    );
    const response: AxiosResponse<WBSEntryModel[]> = await apiRequests.get(url);

    const { data, status } = response;
    if (status >= 200 && status < 300) {
      return data;
    }

    switch (response.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(`Unknown error occurred with status code ${response.status}`);
    }
  } catch (e) {
    // @todo Handle Error Below
    // throw new Error("An unexpected error occurred");
    return null;
  }
};

/**
 * Adds a new deliverable to the project scope.
 * @param {WBSEntryViewModel} deliverable - The deliverable to be added.
 * @returns {Promise<LegacyEditWBSEntry[]>} - A promise that resolves with an array of ProjectScopeModel objects.
 * @throws {Error} - Throws an error if the request fails or returns an error status code.
 */
export const addNewDeliverable = async (
  deliverable: LegacyEditWBSEntry
): Promise<LegacyEditWBSEntry[]> => {
  // @check append predecessor?
  try {
    const url = SHARED_API_ENDPOINTS.ADD_PROJECT_DELIVERABLES;

    const response: AxiosResponse<LegacyEditWBSEntry[]> = await apiRequests.post(
      url,
      deliverable
    );

    const { data, status } = response;
    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
};

/**
 * Adds a cloned deliverable to a project scope.
 * @param deliverable - The cloned deliverable object containing the newDeliverableParentId, newProjectId, and oldDeliverableId.
 * @returns A Promise that resolves to an array of ProjectScopeModel objects.
 * @throws An error if the response status is not 200, 201, 400, 401, or 404.
 */
export const addClonedDeliverable = async (deliverable: {
  newDeliverableParentId: string;
  newProjectId: string;
  oldDeliverableId: string;
  newOrder: number;
}): Promise<ProjectScopeModel[]> => {
  try {
    const url = DELIVERABLES_API_ENDPOINTS.ADD_CLONED_DELIVERABLE;

    const response: AxiosResponse<ProjectScopeModel[]> = await apiRequests.post(
      url,
      deliverable
    );

    const { data, status } = response;
    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
};

/**
 * Deletes the specified deliverables from the server.
 * @param deliverables - An array of strings representing the IDs of the deliverables to delete.
 * @returns A Promise that resolves with an array of ProjectScopeModel objects representing the updated project scope.
 * @throws An error if the server returns a non-successful status code or an unexpected error occurs.
 */
export const deleteDeliverables = async (deliverable: string): Promise<null> => {
  try {
    const url = DELIVERABLES_API_ENDPOINTS.GET_DELIVERABLE_BY_ID.replace(
      "{id}",
      deliverable
    );

    const response = await apiRequests.delete(url);

    if (response.status < 300) {
      return null;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
};

// CLONING STUFF

/**
 * Retrieves a list of projects for cloning.
 * @returns {Promise<any>} A promise that resolves with the list of projects.
 * @throws {Error} If an unexpected error occurs or if the response status is not 200, 400, 401, or 404.
 */
export const getProjectsForClone = async () => {
  try {
    const url = DELIVERABLES_API_ENDPOINTS.GET_PROJECTS_FOR_CLONE;
    const response: AxiosResponse<ProjectScopeModel[]> = await apiRequests.get(url);

    const { data, status } = response;
    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
};

/**
 * Retrieves deliverables for cloning a project.
 * @param project The ID of the project to clone.
 * @returns A Promise that resolves with the deliverables data or undefined if an error occurs.
 * @throws An error if the request fails or returns an error status code.
 */
export const getDeliverablesForClone = async (
  project: string
): Promise<ProjectScopeModel[]> => {
  try {
    const url = DELIVERABLES_API_ENDPOINTS.GET_DELIVERABLES_FOR_CLONE.replace(
      "{programid}",
      project
    );
    const response: AxiosResponse<ProjectScopeModel[]> = await apiRequests.get(url);

    const { data, status } = response;
    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${status}`);
  } catch (e) {
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
};

/**
 * Retrieves the children of a deliverable for cloning.
 * @param outcome - The ID of the deliverable to retrieve children for.
 * @returns A Promise that resolves with an array of ProjectScopeModel objects representing the deliverable's children.
 * @throws An error if the request fails or returns an error status code.
 */
export const getDeliverablesChildrenForClone = async (outcome: string) => {
  try {
    const url = DELIVERABLES_API_ENDPOINTS.GET_DELIVERABLE_CHILDREN.replace(
      "{deliverableid}",
      outcome
    );
    const response: AxiosResponse<ProjectScopeModel[]> = await apiRequests.get(url);

    const { data, status } = response;
    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
};

export const loader = async ({ params }: { params: Params<string> }) => {
  if (!params.id) return null;

  const data = await getProjectDeliverables(params.id);
  return data;
};
