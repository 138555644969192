import ModeEditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import { FormikProps } from "formik";
import { MRT_Row } from "material-react-table";
import { useRef, useState } from "react";

import { RDialog } from "@/shared/components/forms";

import { EditConceptModel } from "../../models/editConceptModel";
import { PortfolioRowModel } from "../../models/portfolioModel";
import EditConceptForm from "../forms/EditConceptForm";

type EditConceptButtonProps = {
  row: MRT_Row<PortfolioRowModel>;
  closePopover: () => void;
  refetchProjects: () => Promise<void>;
  portfolioData: PortfolioRowModel[];
};

export default function EditConceptButton({
  row,
  closePopover,
  refetchProjects,
  portfolioData,
}: EditConceptButtonProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const editFormikRef = useRef<FormikProps<EditConceptModel>>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmitForm = () => {
    if (isModalOpen && editFormikRef.current) {
      void editFormikRef.current.submitForm();
      setModalOpen(false);
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    closePopover();
  };

  return (
    <>
      <MenuItem
        key="edit"
        onClick={handleOpen}
        sx={{
          fontSize: "15px",
        }}>
        <ModeEditIcon fontSize="small" sx={{ marginRight: "5px" }} />
        <strong>Edit</strong>
      </MenuItem>

      <RDialog
        isOpen={isModalOpen}
        title="Edit Concept"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleSubmitForm}
        confirmButtonText="Save"
        confirmButtonType="primary"
        confirmButtonDisabled={!isFormValid}
        size="medium">
        <EditConceptForm
          row={row}
          onClose={handleClose}
          refetchProjects={refetchProjects}
          innerRef={editFormikRef}
          setIsFormValid={setIsFormValid}
          portfolioData={portfolioData}
        />
      </RDialog>
    </>
  );
}
