import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import { RDialog } from "@/shared/components/forms";

import deleteRiskApi from "../api/deleteRiskService";

type DeleteRiskButtonProps = {
  rowSelection: string[];

  onRiskDeleted: () => void;
};

export default function DeleteRiskButton({
  rowSelection,
  onRiskDeleted,
}: DeleteRiskButtonProps) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const deleteRisk = async () => {
    try {
      await deleteRiskApi(rowSelection[0]);
      onRiskDeleted(); // refetches data
    } catch (error) {
      // @todo deal with error
    }
  };

  const handleDelete = () => {
    void deleteRisk();
    handleClose();
  };

  return (
    <>
      <MenuItem key="delete" onClick={handleOpen}>
        <DeleteIcon fontSize="small" />
      </MenuItem>

      <RDialog
        isOpen={isModalOpen}
        title="Delete Selected Risk"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleDelete}
        confirmButtonText="Delete"
        confirmButtonType="error"
        size="small">
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Are you sure you want to delete the selected risk?
        </Typography>
      </RDialog>
    </>
  );
}
