import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete Icon
import Typography from "@mui/material/Typography";
import { MRT_RowSelectionState, MRT_TableInstance } from "material-react-table";
import { useState } from "react";

import { TertiaryButton } from "@/shared/components/buttons";
import { RDialog } from "@/shared/components/forms";
import ProjectType from "@/shared/enums/projectType";

import deleteProjects from "../../api/deleteProjectsService";
import editProject from "../../api/editProjectService";
import determineIfIsProject, { PortfolioRowModel } from "../../models/portfolioModel";

type DeleteProjectButtonProps = {
  tableInstanceRef: MRT_TableInstance<PortfolioRowModel>;
  rowSelection: MRT_RowSelectionState;
  refetchProjects: () => Promise<void>;
};

export default function DeleteProjectButton({
  tableInstanceRef,
  rowSelection,
  refetchProjects,
}: DeleteProjectButtonProps) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleDelete = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (tableInstanceRef) {
      const uniqueIds = [
        ...new Set(
          Object.keys(rowSelection).filter((id) => {
            return rowSelection[id] && !/ - .+/.test(id); // Filter out IDs with suffixes
          })
        ),
      ];

      // Create a Set for selected projects IDs, including only the unique IDs
      const selectedProjectsIDs = new Set(uniqueIds);
      // Store project IDs for which the selected development option is being deleted
      const projectsToUpdate = new Set<string>();
      tableInstanceRef.getRowModel().rows.forEach((row) => {
        if (
          determineIfIsProject(row.original) &&
          selectedProjectsIDs.has(row.original.selectedDevelopmentOptionId)
        ) {
          projectsToUpdate.add(row.original.id);
        }
      });

      try {
        await deleteProjects([...selectedProjectsIDs]);
        await refetchProjects();

        // Get all rows after deletion
        const updatedRows = tableInstanceRef.getRowModel().rows;

        // Iterate over projects to update their selectedDevelopmentOptionId
        for (const row of updatedRows) {
          if (
            determineIfIsProject(row.original) &&
            projectsToUpdate.has(row.original.id)
          ) {
            // Find the first undeleted development option for this project
            const newSelectedOption = updatedRows.find(
              (r) =>
                r.original.type === ProjectType.concept &&
                r.original.parentId === row.original.id &&
                !selectedProjectsIDs.has(r.original.id)
            );

            // Check if there are any remaining concepts
            const hasRemainingConcepts = updatedRows.some(
              (r) =>
                r.original.type === ProjectType.concept &&
                r.original.parentId === row.original.id &&
                !selectedProjectsIDs.has(r.original.id)
            );

            // Only update if there are remaining concepts
            if (hasRemainingConcepts) {
              const updateSelectedOption = {
                ...row.original,
                selectedDevelopmentOptionId: newSelectedOption
                  ? newSelectedOption.original.id
                  : null,
              };

              // Send the editProject request
              await editProject(updateSelectedOption);
              await refetchProjects();
            }
          }
        }
      } catch (error) {
        // @todo handle error
      }

      tableInstanceRef.resetRowSelection();
      setModalOpen(false);
    }
  };

  return (
    <>
      {Object.keys(rowSelection).length > 0 && (
        <TertiaryButton
          sx={{
            marginRight: "5px",
          }}
          icon={<DeleteIcon fontSize="small" />}
          onClick={handleDelete}>
          Delete
        </TertiaryButton>
      )}
      <RDialog
        isOpen={isModalOpen}
        title="Delete Selected Item"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleConfirmDelete}
        confirmButtonText="Delete"
        confirmButtonType="error"
        size="small">
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Are you sure you want to delete the selected item?
        </Typography>
      </RDialog>
    </>
  );
}
