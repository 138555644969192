// @refactor make add and edit one  file and create a function or hook to handle on change
// @refactor the helperText should be a custom component
// @refactor find a better way to deal with empty values in textfield of type number. Alternatively avoid the transform in the yup schema and instead pass a null in the onChange event of the text field
import Box from "@mui/material/Box";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as Yup from "yup";

import addResource from "@/features/cost/resourceCatalogue/api/addResource";
import { AddResourceModel } from "@/features/cost/resourceCatalogue/types/resourcesModel";
import {
  RNumberFormInput,
  RSelectFormInput,
  RTextFormInput,
} from "@/shared/components/forms";
import {
  FIELD_LENGTH,
  FIELD_REQUIRED,
  LONG_NAMES,
  MID_AMOUNT_RANGE,
  SHORT_DESCRIPTION,
  SHORT_NAMES,
} from "@/shared/constants";
import { OutcomeUnitsNames, DurationUnitsNames } from "@/shared/enums/units";
import { validateFormFields } from "@/shared/utils/formValidation";

const validationSchema = Yup.object({
  category: Yup.string().required(FIELD_REQUIRED).max(SHORT_NAMES, FIELD_LENGTH),
  subCategory: Yup.string().required(FIELD_REQUIRED).max(SHORT_NAMES, FIELD_LENGTH),
  supplier: Yup.string().required(FIELD_REQUIRED).max(SHORT_NAMES, FIELD_LENGTH),
  name: Yup.string().required(FIELD_REQUIRED).max(LONG_NAMES, FIELD_LENGTH),
  description: Yup.string().max(SHORT_DESCRIPTION, FIELD_LENGTH),
});

export type CreateNewResourceFormProps = {
  onSubmit: () => void;
  innerRef: RefObject<FormikProps<AddResourceModel>>;
  setIsFormValid?: (isValid: boolean) => void;
};

export default function CreateNewResourceForm({
  onSubmit,
  innerRef,
  setIsFormValid,
}: CreateNewResourceFormProps) {
  //@todo this values should be imported from outside this component.
  const initialValues: AddResourceModel = {
    category: "",
    subCategory: "",
    name: "",
    supplier: "",
    description: "",
    fixedCost: 0,
    unit: 0,
  };

  const units = new Map<number, string>([...DurationUnitsNames, ...OutcomeUnitsNames]);
  const unitOptions = Array.from(units.entries()).map(([key, value]) => ({
    key: value,
    value: key,
    label: value,
  }));
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      innerRef={innerRef}
      onSubmit={async (values, { setStatus }) => {
        try {
          await addResource(values);
          onSubmit();
        } catch (err) {
          if (err instanceof Error) {
            setStatus({ errorMessage: err.message });

            // Set a timeout to clear the error message after 5 seconds
            setTimeout(() => setStatus(null), 5000);
          }
        }
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldTouched,
        validateField,
        setFieldValue,
        isValid,
      }) => {
        validateFormFields<AddResourceModel>(
          isValid,
          touched,
          ["name", "description", "supplier", "subCategory", "category"],
          setIsFormValid
        );
        return (
          <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
            <Form>
              <RTextFormInput
                name="category"
                label="Category"
                value={values.category}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={SHORT_NAMES}
                required
              />
              <RTextFormInput
                name="subCategory"
                label="Subcategory"
                value={values.subCategory}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={SHORT_NAMES}
                required
              />
              <RTextFormInput
                name="supplier"
                label="Supplier"
                value={values.supplier}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={SHORT_NAMES}
                required
              />
              <RTextFormInput
                name="name"
                label="Resource Name"
                value={values.name}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
                required
              />
              <RTextFormInput
                name="description"
                label="Description"
                value={values.description}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={SHORT_DESCRIPTION}
              />
              <RSelectFormInput
                name="unit"
                label="Unit"
                value={values.unit}
                options={unitOptions}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <RNumberFormInput
                name="fixedCost"
                label="Fixed Price"
                value={values.fixedCost}
                allowNegative
                onInputChange={(newValue) => {
                  void setFieldValue("fixedCost", newValue);
                }}
                range={MID_AMOUNT_RANGE}
              />
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}
