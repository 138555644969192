import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FormikProps } from "formik";
import { useRef, useState } from "react";

import EditWBSEntryForm from "@/features/projectScope/components/forms/EditWBSEntryForm";
import { EditWBSEntry } from "@/features/projectScope/types/projectScopeModels";
import { RDialog } from "@/shared/components/forms";
import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";

type EditProjectScopeProps = {
  WBSEntry: WBSEntryViewModel; // Define WBSEntry as a prop of type WBSEntryViewModel
};

export default function EditProjectScope({ WBSEntry }: EditProjectScopeProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const editFormikRef = useRef<FormikProps<EditWBSEntry>>(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const handleSubmitForm = () => {
    if (isModalOpen && editFormikRef.current) {
      void editFormikRef.current.submitForm();
      setModalOpen(false);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          width: "fit-content",
          maxWidth: "fit-content",
          marginTop: "24px",
          transition: "transform 0.2s",
          "&:focus": {
            outline: "none",
          },
          marginLeft: "0.5rem",
        }}
        startIcon={<Edit fontSize="small" />}
        onClick={() => setModalOpen(true)}>
        Edit Outcome
      </Button>

      <RDialog
        isOpen={isModalOpen}
        title="Edit Deliverable"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleSubmitForm}
        confirmButtonType="primary"
        confirmButtonDisabled={!isFormValid}
        confirmButtonText="Confirm"
        size="medium">
        <EditWBSEntryForm
          WBSEntry={WBSEntry}
          innerRef={editFormikRef}
          setIsFormValid={setIsFormValid}
          handleClose={handleClose}
        />
      </RDialog>
    </>
  );
}
