import { Typography } from "@mui/material";
import { useState } from "react";

import { RDialog } from "@/shared/components/forms";
import { getUserInfo } from "@/shared/store/userInfo";

export default function RemoveUsersButton() {
  const [isModalOpen, setModalOpen] = useState(false);
  const user = getUserInfo();

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Typography onClick={handleOpen} sx={{ fontSize: "1.25rem" }}>
        Remove user
      </Typography>
      <RDialog
        isOpen={isModalOpen}
        title="DELETE USER"
        cancelButtonCallback={handleClose}
        confirmButtonText="Remove"
        confirmButtonType="error"
        size="small">
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Are you sure you want to delete <br />
          <strong>{user.fullName}</strong>?
        </Typography>
      </RDialog>
    </>
  );
}
