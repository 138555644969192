import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import { FormikProps } from "formik";
import { useRef, useState } from "react";

import addConcept from "@/features/portfolio/api/addConceptService";
import { AddConceptModel } from "@/features/portfolio/models/addConceptModel";
import { PortfolioRowModel } from "@/features/portfolio/models/portfolioModel";
import { RDialog } from "@/shared/components/forms";

import AddConceptForm from "../forms/AddConceptForm";

export type AddConceptButtonProps = {
  projectId?: string;
  refetchProjects: () => Promise<void>;
  closePopover: () => void;
  portfolioData?: PortfolioRowModel[];
};

export default function AddConceptButton({
  projectId,
  refetchProjects,
  closePopover,
  portfolioData = [],
}: AddConceptButtonProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const conceptFormikRef = useRef<FormikProps<AddConceptModel>>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmitForm = () => {
    if (isModalOpen && conceptFormikRef.current) {
      void conceptFormikRef.current.submitForm();
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    closePopover();
  };

  const addConceptAsync = async (newConcept: AddConceptModel) => {
    try {
      await addConcept(newConcept);
      void refetchProjects(); // Trigger refetching of projects
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw new Error(err.message);
      } else {
        throw new Error(String(err));
      }
    }
  };

  const handleAddConcept = (newConcept: AddConceptModel): void => {
    void addConceptAsync(newConcept);
    handleClose(); // Close the modal
  };

  return (
    <>
      <MenuItem
        onClick={handleOpen}
        sx={{
          fontSize: "15px !important",
        }}>
        <AddIcon fontSize="small" sx={{ marginRight: "5px" }} />
        <strong>New Concept</strong>
      </MenuItem>

      <RDialog
        isOpen={isModalOpen}
        title="Add New Concept"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleSubmitForm}
        confirmButtonText="Create"
        confirmButtonType="primary"
        confirmButtonDisabled={!isFormValid}
        size="medium">
        <AddConceptForm
          projectId={projectId}
          onConfirm={handleAddConcept}
          innerRef={conceptFormikRef}
          portfolioData={portfolioData}
          setIsFormValid={setIsFormValid}
        />
      </RDialog>
    </>
  );
}
