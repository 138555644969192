// @refactor find a better way to deal with empty values in textfield of type number. Alternatively avoid the transform in the yup schema and instead pass a null in the onChange event of the text field
import Box from "@mui/material/Box";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import addRisk from "@/features/risk/api/addRisk";
import { RiskBaseModel } from "@/features/risk/types/riskModels";
import { RNumberFormInput, RTextFormInput } from "@/shared/components/forms";
import {
  DURATION_RISK_RANGE,
  FIELD_LENGTH,
  FIELD_REQUIRED,
  SHORT_NAMES,
  SMALL_AMOUNT_RANGE,
} from "@/shared/constants";
import { validateFormFields } from "@/shared/utils/formValidation";

const validationSchema = yup.object({
  name: yup.string().required(FIELD_REQUIRED).max(SHORT_NAMES, FIELD_LENGTH),
});
export type CreateNewResourceFormProps = {
  innerRef: RefObject<FormikProps<RiskBaseModel>>;
  setIsFormValid?: (isValid: boolean) => void;
};

export default function AddRiskForm({
  innerRef,
  setIsFormValid,
}: CreateNewResourceFormProps) {
  const { outcome } = useParams();
  const initialValues: RiskBaseModel = {
    id: "",
    wbsEntryId: outcome ?? "",
    name: "",
    probabOfOccurrence: null,
    modeDuration: null,
    modeRemedialCost: null,
    impactCost: 0,
    impactDuration: 0,
  };
  //@refactor The form should not handle the api call, instead it should be the AddRisk.tsx
  return (
    <Formik
      initialValues={initialValues}
      innerRef={innerRef}
      validationSchema={validationSchema}
      onSubmit={async (values, { setStatus }) => {
        try {
          await addRisk(values);
        } catch (err) {
          if (err instanceof Error) {
            setStatus({ errorMessage: err.message });
            // Set a timeout to clear the error message after 5 seconds
            setTimeout(() => setStatus(null), 5000);
          }
        }
      }}>
      {({
        setFieldTouched,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        validateField,
        setFieldValue,
        isValid,
      }) => {
        validateFormFields<RiskBaseModel>(isValid, touched, ["name"], setIsFormValid);
        return (
          <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
            <Form>
              <RTextFormInput
                required
                name="name"
                label="Resource Name"
                value={values.name}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={SHORT_NAMES}
              />
              <RNumberFormInput
                name="probabOfOccurrence"
                label="Residual Probability"
                value={values.probabOfOccurrence}
                onInputChange={(newValue) => {
                  void setFieldValue("probabOfOccurrence", newValue);
                }}
                category="percentage"
                allowNegative
              />

              <RNumberFormInput
                name="modeDuration"
                label="Duration"
                value={values.modeDuration}
                onInputChange={(newValue) => {
                  void setFieldValue("modeDuration", newValue);
                }}
                range={DURATION_RISK_RANGE}
                category="duration"
              />

              <RNumberFormInput
                name="modeRemedialCost"
                label="Remedial Cost"
                value={values.modeRemedialCost}
                onInputChange={(newValue) => {
                  void setFieldValue("modeRemedialCost", newValue);
                }}
                range={SMALL_AMOUNT_RANGE}
                allowNegative
                category="currency"
              />
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}
