import { Add } from "@mui/icons-material";
import { FormikProps } from "formik";
import { useRef, useState } from "react";

import CreateNewResourceForm from "@/features/cost/resourceCatalogue/components/createNewResource/CreateNewResourceForm";
import { AddResourceModel } from "@/features/cost/resourceCatalogue/types/resourcesModel";
import { PrimaryButton } from "@/shared/components/buttons";
import { RDialog } from "@/shared/components/forms";

export type CreateNewResourceProps = {
  onCatalogueAndCostRefetch: () => void;
};

export default function CreateNewResource({
  onCatalogueAndCostRefetch,
}: CreateNewResourceProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const newFormikRef = useRef<FormikProps<AddResourceModel>>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmitForm = () => {
    if (isModalOpen && newFormikRef.current) {
      void newFormikRef.current.submitForm();
      setModalOpen(false);
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    onCatalogueAndCostRefetch(); // Trigger refetching of projects
    handleClose(); // Close the modal
  };

  return (
    <>
      <PrimaryButton onClick={handleOpen} icon={<Add fontSize="small" />}>
        New Resource
      </PrimaryButton>
      <RDialog
        isOpen={isModalOpen}
        title="Create New Resource"
        confirmButtonCallback={handleSubmitForm}
        cancelButtonCallback={handleClose}
        confirmButtonText="Create"
        confirmButtonType="primary"
        confirmButtonDisabled={!isFormValid}
        size="medium">
        <CreateNewResourceForm
          onSubmit={handleConfirm}
          innerRef={newFormikRef}
          setIsFormValid={setIsFormValid}
        />
      </RDialog>
    </>
  );
}
