import { MenuItem, TextField } from "@mui/material";
import { ChangeEvent, FocusEvent, ReactNode } from "react";

type SelectOption = {
  key: string | number;
  value: string | number;
  label: string;
  disabled?: boolean;
  chip?: ReactNode;
};

type RSelectFormInputProps = {
  name: string;
  label: string;
  value: string | number;
  options: SelectOption[];
  required?: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleBlur: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  renderValue?: (value: unknown) => ReactNode;
};

const getSelectFormStyles = () => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "#EEF1F4",
  },
  boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.1)",
  "& .MuiFilledInput-underline::before": {
    borderBottom: "none",
  },
  "& .MuiInputLabel-root": {
    color: "primary.main",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "primary.main",
  },
  marginBottom: "2rem !important",
  "&:last-child": {
    marginBottom: "0 !important",
  },
});

export default function RSelectFormInput({
  name,
  label,
  value,
  options,
  required,
  handleChange,
  handleBlur,
  renderValue,
}: RSelectFormInputProps) {
  return (
    <TextField
      select
      required={required}
      name={name}
      label={label}
      value={value}
      variant="filled"
      fullWidth
      onChange={handleChange}
      onBlur={handleBlur}
      SelectProps={{
        renderValue: renderValue,
      }}
      sx={getSelectFormStyles}>
      {options.map((option) => (
        <MenuItem
          key={option.key}
          value={option.value}
          disabled={option.disabled}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          {option.label}
          {option.chip}
        </MenuItem>
      ))}
    </TextField>
  );
}
