/* eslint-disable @typescript-eslint/no-unsafe-assignment -- This is how it is used in Mui documentation */
/* eslint-disable @typescript-eslint/no-explicit-any -- This is how it is used in Mui documentation */

import { TextField } from "@mui/material";
import { useState } from "react";

import NumberInputFormatter from "../inputs/numericInputs/components/NumberInputFormatter";
import { getRangeByCategory } from "../inputs/numericInputs/helpers/numberFormatHelpers";

type RNumberProps = {
  name: string;
  label: string;
  value: string | number | null | undefined;
  onInputChange: (value: number) => void;
  range?: { min: number; max: number };
  allowNegative?: boolean;
  decimalScale?: number;
  category?:
    | "currency"
    | "emissions"
    | "duration"
    | "percentage"
    | "quantity"
    | "outcome"
    | "per outcome";
};

const getUserFormStyles = {
  "& .MuiFilledInput-root": {
    backgroundColor: "#EEF1F4",
  },
  boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.1)",
  "& .MuiFilledInput-underline::before": {
    borderBottom: "none",
  },
  "& .MuiInputLabel-root": {
    color: "primary.main", // Red label if there's an error, otherwise blue
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "primary.main", // Red label if there's an error when focused, otherwise blue
  },
  marginBottom: "2rem !important",
};
export default function RNumberFormInput({
  name,
  label,
  value,
  allowNegative = false,
  decimalScale = 2,
  range,
  onInputChange,
  category = undefined,
}: RNumberProps) {
  const validRange = range ? range : getRangeByCategory(category);
  // Ensure value defaults to 0 if it's undefined, null, or NaN
  const normalizedValue = value == null || isNaN(Number(value)) ? 0 : Number(value);

  const [inputValue, setValues] = useState<number>(normalizedValue);

  const handleBlur = () => {
    if (inputValue !== value) {
      onInputChange(inputValue);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setValues(value);
  };

  return (
    <TextField
      name={name}
      label={label}
      variant="filled"
      value={normalizedValue}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={(input) => input.target.select()}
      sx={getUserFormStyles}
      slotProps={{
        input: {
          inputComponent: NumberInputFormatter as any,
          inputProps: {
            allowNegative,
            decimalScale,
            validRange,
          },
        },
      }}
      fullWidth
    />
  );
}
