import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MenuItem from "@mui/material/MenuItem";
import { FormikProps } from "formik";
import { useRef, useState } from "react";

import cloneProject from "@/features/portfolio/api/cloneProjectService";
import { CloneModel } from "@/features/portfolio/models/cloneModel";
import { RDialog } from "@/shared/components/forms";

import { PortfolioRowModel, Project } from "../../models/portfolioModel";
import CloneForm from "../forms/CloneForm";

export type CloneProjectButtonProps = {
  project: PortfolioRowModel;
  closePopover: () => void;
  addCloneProject: (newProject: Project) => void;
};

export default function CloneProjectButton({
  project,
  closePopover,
  addCloneProject,
}: CloneProjectButtonProps) {
  const cloneProjectFormikRef = useRef<FormikProps<CloneModel>>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmitForm = () => {
    if (isModalOpen && cloneProjectFormikRef.current) {
      void cloneProjectFormikRef.current.submitForm();
      handleClose();
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    closePopover();
  };

  const cloneProjectAsync = async (newProject: CloneModel) => {
    try {
      const clonedProject = await cloneProject(newProject);
      addCloneProject(clonedProject);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Error cloning project: ${error.message}`);
      } else {
        throw new Error("Error cloning project: An unknown error occurred");
      }
    }
  };

  const handleCloneProject = (newProject: CloneModel) => {
    void cloneProjectAsync(newProject);
    handleClose();
  };

  return (
    <>
      <MenuItem
        onClick={handleOpen}
        sx={{
          fontSize: "15px !important",
        }}>
        <ContentCopyIcon fontSize="small" sx={{ marginRight: "5px" }} />
        <strong>Clone</strong>
      </MenuItem>

      <RDialog
        isOpen={isModalOpen}
        title="Clone"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleSubmitForm}
        confirmButtonText="Clone"
        confirmButtonType="primary"
        confirmButtonDisabled={!isFormValid}
        size="medium">
        <CloneForm
          portfolioItem={project}
          onClone={handleCloneProject}
          innerRef={cloneProjectFormikRef}
          setIsFormValid={setIsFormValid}
        />
      </RDialog>
    </>
  );
}
