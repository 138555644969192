import Box from "@mui/material/Box";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import { CloneModel } from "@/features/portfolio/models/cloneModel";
import { PortfolioRowModel } from "@/features/portfolio/models/portfolioModel";
import { RTextFormInput, RNumberFormInput } from "@/shared/components/forms";
import {
  FIELD_LENGTH,
  FIELD_REQUIRED,
  LONG_NAMES,
  PROJECT_CODE_RANGE,
} from "@/shared/constants";
import ProjectType from "@/shared/enums/projectType";
import { validateFormFields } from "@/shared/utils/formValidation";

type CloneFormProps = {
  portfolioItem: PortfolioRowModel;
  projectCode?: number;
  onClone: (newProject: CloneModel) => void;
  innerRef: RefObject<FormikProps<CloneModel>>;
  setIsFormValid?: (isValid: boolean) => void;
};

export default function CloneForm({
  portfolioItem,
  projectCode,
  onClone,
  innerRef,
  setIsFormValid,
}: CloneFormProps) {
  const initialValues = {
    id: portfolioItem.id ?? "",
    newName: `CLONE- ${portfolioItem.name}`,
    projectId: portfolioItem.parentId ?? "",
    projectCode: projectCode ?? 0,
  };

  const validationSchema = yup.object({
    newName: yup.string().max(LONG_NAMES, FIELD_LENGTH).required(FIELD_REQUIRED),
    ...(portfolioItem.type === ProjectType.concept && {
      projectCode: yup.number(),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={innerRef}
      onSubmit={(values, { setStatus }) => {
        try {
          onClone(values);
        } catch (err) {
          if (err instanceof Error) {
            setStatus({ errorMessage: err.message });
            setTimeout(() => setStatus(null), 5000);
          }
        }
      }}>
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        setFieldTouched,
        validateField,
        isValid,
        setFieldValue,
      }) => {
        validateFormFields<CloneModel>(
          isValid,
          touched,
          ["newName"],
          setIsFormValid,
          true
        );

        return (
          <Box sx={{ paddingTop: "1rem" }}>
            <Form>
              <RTextFormInput
                required
                name="newName"
                label="New Name"
                value={values.newName}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
              />
              {portfolioItem.type === ProjectType.concept && (
                <RNumberFormInput
                  name="projectCode"
                  label="Project Code"
                  value={values.projectCode}
                  onInputChange={(newValue) => {
                    void setFieldValue("projectCode", newValue);
                  }}
                  decimalScale={0}
                  range={PROJECT_CODE_RANGE}
                />
              )}
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}
