import Box from "@mui/material/Box";
import { MRT_RowSelectionState, MRT_TableInstance } from "material-react-table";

import AddProjectButton from "@/features/portfolio/components/buttons/AddProject";
import DeleteProjectButton from "@/features/portfolio/components/buttons/DeleteProject";
import { PortfolioRowModel } from "@/features/portfolio/models/portfolioModel";

type PortfolioToolbarProps = {
  refetchProjects: () => Promise<void>;
  tableInstanceRef: MRT_TableInstance<PortfolioRowModel>;
  rowSelection: MRT_RowSelectionState;
  portfolioData: PortfolioRowModel[];
};

export default function PortfolioToolbar({
  refetchProjects,
  tableInstanceRef,
  rowSelection,
  portfolioData,
}: PortfolioToolbarProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 2,
      }}>
      <DeleteProjectButton
        tableInstanceRef={tableInstanceRef}
        rowSelection={rowSelection}
        refetchProjects={refetchProjects}
      />
      <AddProjectButton refetchProjects={refetchProjects} portfolioData={portfolioData} />
    </Box>
  );
}
