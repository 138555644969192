import { Box } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import { addNewDeliverable } from "@/features/projectScope/api/projectScopeService";
import { updateAddWBSEntry } from "@/features/projectScope/helpers/projectScopeHelpers";
import { AddWBSEntryModel } from "@/features/projectScope/types/projectScopeModels";
import { RTextFormInput } from "@/shared/components/forms";
import {
  FIELD_REQUIRED,
  LONG_NAMES,
  FIELD_LENGTH,
  LONG_DESCRIPTION,
} from "@/shared/constants";
import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { validateFormFields } from "@/shared/utils/formValidation";

const validationSchema = yup.object({
  name: yup.string().required(FIELD_REQUIRED).max(LONG_NAMES, FIELD_LENGTH),
  description: yup.string().max(LONG_DESCRIPTION, FIELD_LENGTH).notRequired(),
});

export type AddDeliverableFormType = {
  wbsEntry: WBSEntryViewModel;
  innerRef: RefObject<FormikProps<AddWBSEntryModel>>;
  setIsFormValid?: (isValid: boolean) => void;
  handleClose: () => void;
};

export default function AddWBSEntryForm({
  wbsEntry,
  innerRef,
  setIsFormValid,
  handleClose,
}: AddDeliverableFormType): JSX.Element {
  const { refetchProjectWBS } = useProjectWBSContext();

  return (
    <Formik
      onSubmit={async (newValues) => {
        try {
          const newWBSEntry = updateAddWBSEntry(wbsEntry, newValues);
          await addNewDeliverable(newWBSEntry);
          handleClose();
          refetchProjectWBS();
        } catch (error) {
          // @todo handle error
        }
      }}
      initialValues={{
        name: "",
        description: "",
        level: 0,
      }}
      validateOnMount
      validationSchema={validationSchema}
      innerRef={innerRef}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        validateField,
        setFieldTouched,
      }) => {
        // Set form validity
        validateFormFields<AddWBSEntryModel>(
          isValid,
          touched,
          ["name"],
          setIsFormValid,
          true
        );

        return (
          <Box>
            <Form style={{ gap: "0.5rem", flexDirection: "column", display: "flex" }}>
              <RTextFormInput
                required
                name="name"
                label="Deliverable Name"
                value={values.name}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
              />
              <RTextFormInput
                label="Statement"
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_DESCRIPTION}
                multiline
                rows={5}
              />
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}
