import Box from "@mui/material/Box";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import { isDuplicateProjectName } from "@/features/portfolio/utils/portfolioHelpers";
import { RSelectFormInput, RTextFormInput } from "@/shared/components/forms";
import {
  FIELD_IN_USE,
  FIELD_LENGTH,
  FIELD_REQUIRED,
  LONG_NAMES,
} from "@/shared/constants";
import LifeCycleStage from "@/shared/enums/lifeCycleStage";
import ProjectRegion from "@/shared/enums/projectRegion";
import Risk from "@/shared/enums/risk";
import { validateFormFields } from "@/shared/utils/formValidation";

import { AddProjectModel } from "../../models/addProjectModel";
import { PortfolioRowModel } from "../../models/portfolioModel";

export type AddProjectFormProps = {
  onConfirm: (newProject: AddProjectModel) => void;
  innerRef: RefObject<FormikProps<AddProjectModel>>;
  portfolioData: PortfolioRowModel[];
  setIsFormValid?: (isValid: boolean) => void;
};

export default function AddProjectForm({
  onConfirm,
  innerRef,
  portfolioData,
  setIsFormValid,
}: AddProjectFormProps) {
  const validationSchema = yup.object({
    name: yup
      .string()
      .max(LONG_NAMES, FIELD_LENGTH)
      .required(FIELD_REQUIRED)
      .test(
        "unique-name",
        FIELD_IN_USE,
        (value) => !value || !isDuplicateProjectName(value, portfolioData)
      ),
    programmeName: yup.string().max(LONG_NAMES, FIELD_LENGTH).required(FIELD_REQUIRED),
  });

  const regionOptions = Object.values(ProjectRegion)
    .filter((value) => typeof value === "string")
    .map((value, index) => ({
      key: value,
      value: index,
      label: value,
    }));

  return (
    <Formik
      initialValues={{
        name: "",
        programmeName: "",
        region: ProjectRegion["Asia Pacific"],
        lifeCycleStage: LifeCycleStage.Execute + 1,
        riskLevel: Risk["Very High"] + 1,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        try {
          onConfirm(values);
        } catch (err) {
          if (err instanceof Error) {
            throw new Error(`Failed to confirm project: ${err.message}`);
          } else {
            throw new Error("Failed to confirm project: An unknown error occurred");
          }
        }
      }}
      innerRef={innerRef}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldTouched,
        validateField,
        isValid,
      }) => {
        validateFormFields<AddProjectModel>(
          isValid,
          touched,
          ["name", "programmeName"],
          setIsFormValid
        );

        return (
          <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
            <Form>
              <RTextFormInput
                required
                name="name"
                label="Project Name"
                value={values.name}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
              />
              <RTextFormInput
                required
                name="programmeName"
                label="Programme Name"
                value={values.programmeName}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                validateField={validateField}
                maxLength={LONG_NAMES}
              />
              <RSelectFormInput
                required
                name="region"
                label="Region"
                value={values.region}
                options={regionOptions}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}
