import { FormikTouched } from "formik";

export const validateFormFields = <T extends Record<string, unknown>>(
  isValid: boolean,
  touched: FormikTouched<T>,
  requiredFields: (keyof T)[],
  setIsFormValid?: ((isValid: boolean) => void) | undefined,
  isEdit?: boolean // New parameter with default value
): void => {
  const allRequiredFieldsTouched =
    isEdit ?? requiredFields.every((field) => touched[field as string]);

  if (setIsFormValid) {
    setIsFormValid(isValid && allRequiredFieldsTouched);
  }
};
