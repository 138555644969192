import CloseIcon from "@mui/icons-material/Close";
import { Typography, Dialog, DialogContent, AppBar, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";

import ResourceCatalogueTable from "@/features/cost/resourceCatalogue/components/table/ResourcesTable";

type CostToolbarProps = {
  key: string;
  wbsEntryId: string;
  handleAddResource: () => void;
};
export default function CostToolbar({ wbsEntryId, handleAddResource }: CostToolbarProps) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ paddingBottom: "24px" }}>
      <Button
        onClick={handleOpen}
        variant="outlined"
        size="large"
        sx={{ marginTop: "4px", marginLeft: "4px", fontWeight: "bold" }}>
        Add Resource
      </Button>

      <Dialog fullScreen open={isModalOpen} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h5">
              Resource Catalogue
            </Typography>
            <Button
              variant="text"
              size="large"
              onClick={handleClose}
              sx={{
                color: "#fff",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}>
              <CloseIcon fontSize="large" />
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent sx={{ padding: "0 !important" }}>
          <ResourceCatalogueTable
            handleAddResource={handleAddResource}
            outcome={wbsEntryId}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
